import moment from 'moment';
import { NavigateFunction } from 'react-router-dom';
import { NamePathMapper } from '../container/application/Application';
import { LoginResponse } from '../container/login/Login';
import { colors } from '../theme/Theme';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const { gtag } = require('ga-gtag');

const UrlRegex = new RegExp('(https?://)([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?');

const MAX_FILE_SIZE = 5124;

const AllowedFileTypes = ['jpg', 'jpeg', 'png', 'txt', 'pdf', 'doc', 'docx'];

export const CensorWord = (str: string) => {
  return str[0] + '*'.repeat(str.length - 2) + str.slice(-1);
};

export const CensorEmail = (email: string) => {
  if (!email) return 'your email address';
  const arr = email.split('@');
  return CensorWord(arr[0]) + '@' + CensorWord(arr[1]);
};

const EmailRegex =
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const PhoneRegex = /^\d{9}$/;

const PasswordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[\^$*.\[\]{}\(\)?\-\"!@#%&\/,><\':;|_~`])\S{8,99}$/;

const DateRegex =
  /^(?:(?:31(\/)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{4})$/;

const AmountRegex = /^[0-9]+$/;

const AmountRegexWithDecimal = /^[0-9]*\.[0-9]{1,2}$/;

export const ValidatePassword = (pw: string | null | undefined) => {
  if (!pw || pw.length < 8 || !PasswordRegex.test(pw)) {
    return 'Please enter a valid password';
  } else return undefined;
};

export const ValidateEmail = (email: string | null | undefined) => {
  return email ? EmailRegex.test(email) : false;
};

export const ValidatePhone = (phoneNo: string | null | undefined) => {
  const phone = phoneNo?.startsWith('0') && phoneNo.length === 10 ? phoneNo.slice(1) : phoneNo;
  return phone ? PhoneRegex.test(phone) : false;
};

export const formatPhone = (phoneNo: string) => {
  const phone = phoneNo?.startsWith('0') && phoneNo.length === 10 ? phoneNo.slice(1) : phoneNo;
  return `+61${phone}`;
};

export const ValidateUrls = (url: string) => {
  return url ? UrlRegex.test(url) : false;
};

export const ValidateDate = (date: string | null | undefined) => {
  return date ? DateRegex.test(date) : false;
};

export const ValidateAmount = (amount: string | null | undefined) => {
  return amount ? AmountRegex.test(amount) || AmountRegexWithDecimal.test(amount) : false;
};

export const isOpenAuth = (pathname: string) => {
  const openAuths = [
    'register',
    'forgot-password',
    'register-healthcare-professional',
    'register-supplier',
    'verify',
    'verify-forgot-password',
    'login',
  ];
  return openAuths.includes(pathname.replace('/', ''));
};

export const isAuthenticatedAuth = (pathname: string) => {
  let path = pathname.replace(/\//g, '');
  if (path.includes('summary')) {
    const pathArray = path.split('/');
    pathArray.pop();
    path = pathArray.join('');
  }
  const authenticatedAuths = [
    '',
    'new-applicationsupplier',
    'new-applicationbeneficiary',
    'new-applicationsummary',
    'beneficiary-details',
    'summary',
    'application-tracking',
    'my-applications',
    'account-settings',
    'help',
  ];
  return authenticatedAuths.includes(path);
};

export const logOut = (navigate: NavigateFunction) => {
  localStorage.clear();
  navigate('/login');
};

export const forceLogout = () => {
  localStorage.clear();
  window.location.href = `/login`;
};

export const setTokens = (response: LoginResponse) => {
  const { accessToken, refreshToken, expiry, userId } = response;
  gtag('event', 'user login', {
    userId: `${userId}`,
  });
  localStorage.setItem('accessToken', accessToken);
  localStorage.setItem('refreshToken', refreshToken);
  localStorage.setItem('expiry', expiry);
  localStorage.setItem('userId', userId);
  localStorage.setItem(
    'expiryDurationInMinutes',
    moment(expiry).diff(moment(), 'minutes').toString(),
  );
};

export const GetFileNames = (files: any): string[] => {
  if (!files) return [];
  const names = [];
  for (let i = 0; i < files.length; i++) {
    names.push(files[i].name);
  }
  return names;
};

export const GetKeyByValue = (object: any, value: any): string => {
  return (
    Object.keys(object).find((key) => object[key] === value) ||
    `Document-${moment().format('hh-mma-DD-MM-YYYY')}.pdf`
  );
};

export const GetFilePaths = (files: any, fileNamePathMapper: NamePathMapper): string[] => {
  if (!files) return [];
  const paths = [];
  for (let i = 0; i < files.length; i++) {
    paths.push(fileNamePathMapper[files[i].name]);
  }
  return paths;
};

export const ValidateFiles = (files: any) => {
  if (!files) return [false, ''];
  for (let i = 0; i < files.length; i++) {
    if (Math.round(files[i].size / 1024) > MAX_FILE_SIZE)
      return [false, 'File size should be less than 4Mb'];
    const temp = files[i].name.split('.');
    const ext = temp[temp.length - 1];
    if (!AllowedFileTypes.includes(ext.toLowerCase())) return [false, 'File type not supported'];
  }
  return [true, ''];
};

export const CRUMBS = {
  'new-application': 'Application',
  'supplier': 'Supplier and items',
  'beneficiary': 'Beneficiary and carer',
  'summary': 'Summary',
  'application-tracking': 'Application Tracking',
  'my-applications': 'Applications',
  'account-settings': 'Account Settings',
  '': 'Home',
  'help': 'Help',
};

export const hasAdminPermission = () => {
  const profile = JSON.parse(localStorage.getItem('profile')!);
  let flag = false;
  if (profile) {
    const roles: string[] = profile.roles;
    roles.forEach((role: string) => {
      if (role.toLowerCase().includes('admin')) {
        flag = true;
      }
    });
  }
  return flag;
};

export const hasMemberPermission = () => {
  const profile = JSON.parse(localStorage.getItem('profile')!);
  let flag = false;
  if (profile) {
    const roles: string[] = profile.roles;
    roles.forEach((role: string) => {
      if (role.toLowerCase().includes('board_member')) {
        flag = true;
      }
    });
  }
  return flag && !hasAdminPermission();
};

export const hasPractitionerPermission = () => {
  const profile = JSON.parse(localStorage.getItem('profile')!);
  let flag = false;
  if (profile) {
    const roles: string[] = profile.roles;
    roles.forEach((role: string) => {
      if (role.toLowerCase().includes('health_professional')) {
        flag = true;
      }
    });
  }
  return flag && !hasMemberPermission() && !hasAdminPermission();
};

export const getStatusColor = (status: string) => {
  if (!status) return undefined;
  switch (status) {
    case 'Approved':
      return colors.approved;
    case 'Rejected':
    case 'Deleted':
      return colors.rejected;
    case 'Pending':
    case 'Draft':
      return colors.pending;
    case 'Quote not final':
      return colors.quoteNotFinal;
    case 'Awaiting approval':
    case 'Submitted':
    case 'Request sent':
      return colors.awaitingApproval;
    case 'Loading':
      return colors.sent;
    case 'Off':
      return colors.sent;
    case 'In progress':
      return colors.activeFilter;
    case 'Delivery':
      return colors.primary;
    case 'Complete':
      return colors.approved;
    default:
      return colors.sent;
  }
};

export function dollarFormatter(amount: any) {
  const formatter = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
  });
  return `$${formatter.format(amount)}`;
}

export function CapitalizeFirstLetter(text: string | undefined | null) {
  if (!text) return text;
  return text.charAt(0).toUpperCase() + text.slice(1);
}

export function GetInititals(fullName: string) {
  if (!fullName) return '';
  return fullName
    .split(' ')
    .map((n) => n[0])
    .join('');
}

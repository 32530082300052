import {
  Box,
  CssBaseline,
  ThemeProvider,
  Typography,
  Dialog,
  Snackbar,
  Alert,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import theme from './theme/Theme';
import { StyledEngineProvider } from '@mui/material/styles';
import Layout from './container/layout/Layout';
import { isOpenAuth, logOut, setTokens } from './helper/AppHelper';
import { useIdleTimer } from 'react-idle-timer';
import moment from 'moment';
import AxiosInstance from './service/AxiosInstance';
import { useAppDispatch } from './app/hooks';
import { getProfile } from './service/profile/ProfileSlice';
import PreloadImages from './helper/PreloadImage';
import ErrorBoundary from './container/layout/ErrorBoundary';
// eslint-disable-next-line
const { gtag } = require('ga-gtag');

const style = {
  width: { xs: 400, sm: 600 },
  bgcolor: 'background.paper',
  p: { xs: 3, sm: 6 },
};

const FREQUENCY_FACTOR =
  process.env.REACT_APP_FREQUENCY_FACTOR && parseInt(process.env.REACT_APP_FREQUENCY_FACTOR)
    ? parseInt(process.env.REACT_APP_FREQUENCY_FACTOR)
    : 3;

const App: FC<{}> = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    gtag('event', 'pageview', {
      path: `${location.pathname} ${location.search}`,
    });
  }, [location.pathname, location.search]);

  const [openToast, setOpenToast] = useState(false);
  const expiryDuration = localStorage.getItem('expiryDurationInMinutes')
    ? parseInt(localStorage.getItem('expiryDurationInMinutes')!)
    : 60;

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  useEffect(() => {
    PreloadImages();
  }, []);

  useEffect(() => {
    const auth = !!localStorage.getItem('accessToken');
    if (auth && isOpenAuth(location.pathname)) {
      navigate('/');
    }
    const fetchData = async () => {
      const response = await dispatch(getProfile());
      if (response.type.includes('fulfilled')) {
        localStorage.setItem('profile', JSON.stringify(response.payload));
      }
    };
    const profile = localStorage.getItem('profile');
    if (!profile && auth) fetchData().catch(console.error);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const onPrompt = () => {
    setOpen(true);
  };

  const refetch = () => {
    const instance = AxiosInstance;
    const currentRefreshToken = localStorage.getItem('refreshToken');
    const userId = localStorage.getItem('userId');
    instance
      .post('auth/refresh-token', {
        refreshToken: currentRefreshToken,
        userId: userId,
      })
      .then((response: any) => {
        setTokens(response.data);
      })
      .catch((err: any) => {
        console.log('Could not refresh access token', err);
        logOut(navigate);
        return null;
      });
  };

  const onAction = () => {
    const expiry = localStorage.getItem('expiry');
    if (expiry) {
      const elapsedTime = expiryDuration - moment(expiry).diff(moment(), 'minutes');
      // force logout
      if (elapsedTime > expiryDuration) {
        setOpen(false);
        setOpenToast(true);
        logOut(navigate);
        return;
      }
      // show a prompt to user
      else if (elapsedTime > expiryDuration - 5) {
        setOpen(true);
        return;
      }
      if (elapsedTime > expiryDuration / FREQUENCY_FACTOR) {
        refetch();
        return;
      }
    }
  };

  const onIdle = () => {
    const expiry = localStorage.getItem('expiry');
    if (expiry) {
      const elapsedTime = expiryDuration - moment(expiry).diff(moment(), 'minutes');
      // force logout
      if (elapsedTime > expiryDuration) {
        setOpen(false);
        setOpenToast(true);
        logOut(navigate);
        return;
      }
      // show a prompt to user
      else if (elapsedTime >= expiryDuration - 5) {
        setOpen(true);
        return;
      }
    }
  };

  const {} = useIdleTimer({
    onPrompt,
    onIdle,
    onAction,
    timeout: 1000 * 60 * (expiryDuration - 5), // 55 mins on idle duration
    throttle: 1000 * 60 * 20, // 20 min onAction throttle
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
    ],
    crossTab: true,
    syncTimers: 200,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" flexDirection="column" justifyContent="space-between">
          <Box className="App">
            <ErrorBoundary>
              <Layout>
                <Outlet />
              </Layout>
            </ErrorBoundary>
          </Box>
        </Box>
        <Dialog
          open={open}
          onClose={() => {
            setOpen(false);
            refetch();
          }}
        >
          <Box sx={style}>
            <Typography>You will be logged out in 5 minutes if you aren't active.</Typography>
          </Box>
        </Dialog>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={openToast}
          autoHideDuration={6000}
          onClose={handleCloseToast}
        >
          <Alert onClose={handleCloseToast} severity="info" sx={{ width: '100%' }}>
            Session expired, please login again.
          </Alert>
        </Snackbar>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;

import {
  Avatar,
  Box,
  Breadcrumbs,
  CircularProgress,
  Drawer,
  IconButton,
  Input,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import { useLocation, useNavigate } from 'react-router-dom';
import logo from '../../assets/images/mad_logo.png';
import DashboardIcon from '../../assets/images/DashboardIcon.png';
import ApplicationIcon from '../../assets/images/ApplicationIcon.png';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import avatar from '../../assets/images/avatar.png';
import {
  CRUMBS,
  GetInititals,
  hasAdminPermission,
  hasMemberPermission,
  isAuthenticatedAuth,
  logOut,
} from '../../helper/AppHelper';
import LogoutIcon from '@mui/icons-material/Logout';
import { postLogout, selectLoading } from '../../service/login/LoginSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { colors } from '../../theme/Theme';
import SearchIcon from '@mui/icons-material/Search';
import {
  clearResults,
  postSearchApplication,
  searchKeyString,
  selectSearchApplicationLoading,
  setSearchKey,
} from '../../service/application/SearchSlice';
import ClearIcon from '@mui/icons-material/Clear';
import {
  getProfileImageLink,
  selectData,
  selectGetProfilePictureData,
} from '../../service/profile/ProfileSlice';

const drawerWidth = 240;

const container = window.document.body;

const Layout: FC<any> = ({ children }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const loading = useAppSelector(selectLoading);
  const searchKey = useAppSelector(searchKeyString);
  const searchLoading = useAppSelector(selectSearchApplicationLoading);
  const profileData = useAppSelector(selectData);
  const profilePic = localStorage.getItem('profilePictureLink');

  const profile = JSON.parse(localStorage.getItem('profile')!);
  const { picture } = profile || {};
  const id = picture ? picture.id : null;
  const [imageLink, setImageLink] = useState<string | null>(profilePic);

  const imageData = useAppSelector(selectGetProfilePictureData);
  const imageURL = imageData?.message;
  if (imageURL && !profilePic && profile) {
    localStorage.setItem('profilePictureLink', imageURL);
  }

  const getImageLink = async () => {
    const response = await dispatch(getProfileImageLink({ path: id }));
    if (response.type.includes('fulfilled')) {
      localStorage.setItem('profilePictureLink', response.payload.message);
      setImageLink(response.payload.message);
    }
  };

  useEffect(() => {
    if (!profilePic && id) {
      getImageLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const loggedInUser =
    profileData && profileData.name ? profileData.name.fullName : profile?.name?.fullName;
  const loggedInUserFirstName =
    profileData && profileData.name ? profileData.name.firstName : profile?.name?.firstName;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const setSearchKeyString = (value: string) => {
    dispatch(setSearchKey(value));
    if (!value) {
      dispatch(clearResults());
    }
  };

  const searchApplication = async () => {
    await dispatch(postSearchApplication(searchKey));
  };

  const handleLogout = async () => {
    await dispatch(postLogout());
    setMobileOpen(false);
    logOut(navigate);
  };

  const isActive = (item: string) => {
    const { pathname } = location;
    switch (item) {
      case 'Dashboard':
        if (pathname === '/') return true;
        break;
      case 'Applications':
        if (pathname.toLowerCase().includes('application')) return true;
        break;
      case 'New application':
        if (pathname.includes('new-application')) return true;
        break;
      case 'All applications':
        if (pathname.includes('application-tracking')) return true;
        break;
      case 'My applications':
        if (pathname.includes('my-applications')) return true;
        break;
      case 'Settings':
        if (pathname.includes('account-settings')) return true;
        break;
      default:
        return false;
    }
  };

  const getBreadCrumbs = () => {
    const crumbs: string[] = location.pathname.substring(1).split('/');
    return crumbs.map((crumb: string, index: number) => (
      <Typography
        key={`${crumb}-${index}`}
        color={index === crumbs.length - 1 ? 'text.primary' : ''}
      >
        {(CRUMBS as any)[crumb] || crumb}
      </Typography>
    ));
  };

  const drawer = (
    <Box mt={3}>
      <Box pl="4px" display="flex">
        <img
          title="Go to Home"
          onClick={() => navigate('/')}
          width="100px"
          height="44px"
          style={{ cursor: 'pointer', marginBottom: '15px' }}
          src={logo}
        />
      </Box>
      <Box height="100%" display="flex" flexDirection="column" justifyContent="space-between">
        <List style={{ background: colors.white, zIndex: 1 }}>
          <ListItem key={'Dashboard'} disablePadding>
            <ListItemButton
              onClick={() => {
                setMobileOpen(false);
                navigate('/');
              }}
              selected={isActive('Dashboard')}
            >
              <ListItemIcon>
                {<img style={{ marginLeft: 3 }} height={13} width={17} src={DashboardIcon} />}
              </ListItemIcon>
              <ListItemText primary={'Dashboard'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'My applications'} disablePadding>
            <ListItemButton
              selected={isActive('My applications')}
              onClick={() => {
                setMobileOpen(false);
                navigate('/my-applications');
              }}
            >
              <ListItemIcon>
                {<img style={{ marginLeft: 6 }} height={16} width={14} src={ApplicationIcon} />}
              </ListItemIcon>
              <ListItemText primary={'Applications'} />
            </ListItemButton>
          </ListItem>
        </List>
        <List style={{ position: 'fixed', bottom: 0, width: drawerWidth - 5, paddingBottom: 4 }}>
          <ListItem key={'Help'} disablePadding>
            <ListItemButton
              onClick={() => {
                setMobileOpen(false);
                navigate('/help');
              }}
              selected={isActive('Help')}
            >
              <ListItemIcon>{<HelpOutlineIcon />}</ListItemIcon>
              <ListItemText primary={'Help'} />
            </ListItemButton>
          </ListItem>
          <ListItem key={'Settings'} disablePadding>
            <ListItemButton
              onClick={() => {
                setMobileOpen(false);
                navigate('/account-settings');
              }}
              selected={isActive('Settings')}
            >
              <ListItemIcon>
                <Avatar
                  sx={{ height: 24, width: 24, fontSize: 13, bgColor: colors.grey800 }}
                  alt="Profile Pic"
                  src={imageURL || imageLink!}
                >
                  {GetInititals(loggedInUserFirstName)}
                </Avatar>
              </ListItemIcon>
              <ListItemText primary={loggedInUser || 'Loading...'} />
            </ListItemButton>
          </ListItem>
          <ListItem onClick={handleLogout} key={'Logout'} disablePadding>
            <ListItemButton>
              <ListItemIcon>{<LogoutIcon />}</ListItemIcon>
              <ListItemText
                primary={
                  <Box display="flex" alignItems="center">
                    <Box>{loading === 'loading' ? 'Logging out...' : 'Logout'}</Box>
                    {loading === 'loading' && (
                      <CircularProgress color="inherit" style={{ marginLeft: 15 }} size={18} />
                    )}
                  </Box>
                }
              />
            </ListItemButton>
          </ListItem>
        </List>
      </Box>
    </Box>
  );

  return (
    <div>
      {isAuthenticatedAuth(location.pathname) ? (
        <Box sx={{ display: { sm: 'flex' } }}>
          <Toolbar
            sx={{ display: { xs: 'flex', sm: 'none' }, position: 'sticky', top: 0, zIndex: 99 }}
            style={{ background: colors.white, borderBottom: `1px solid ${colors.border}` }}
          >
            <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
              <IconButton
                color="inherit"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2, display: { sm: 'none' } }}
              >
                <MenuIcon />
              </IconButton>
              <img style={{ width: 100, height: 44 }} src={logo} />
              <Avatar sx={{ height: 32, width: 32 }} alt="Profile picture" src={avatar} />
            </Box>
          </Toolbar>
          <Box component="nav" sx={{ width: { sm: 0 }, flexShrink: { sm: 0 } }}>
            <Drawer
              container={container}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              ModalProps={{
                keepMounted: true,
              }}
              style={{ height: '100%' }}
              sx={{
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  borderLeft: `4px solid ${colors.pageBorder}`,
                },
              }}
            >
              {drawer}
            </Drawer>
            <Drawer
              variant="permanent"
              sx={{
                display: { xs: 'none', sm: 'block' },
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  borderLeft: '4px solid #007DDC',
                },
              }}
              open
            >
              {drawer}
            </Drawer>
          </Box>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
              ml: { xs: 0, sm: 23 },
            }}
          >
            <Box
              sx={{
                display: { xs: 'none', sm: 'flex' },
                background: colors.white,
                position: 'sticky',
                top: 0,
                zIndex: 99,
                borderBottom: `1px solid ${colors.border}`,
              }}
              display="flex"
              flexDirection="row-reverse"
              justifyContent="space-between"
            >
              <Toolbar style={{ width: '100%' }}>
                <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
                  <Breadcrumbs sx={{ pl: '70px' }}>{getBreadCrumbs()}</Breadcrumbs>
                  <Box display="flex" height="100%" alignItems="center">
                    {location.pathname.includes('my-applications') &&
                      (hasMemberPermission() || hasAdminPermission()) && (
                        <Box
                          sx={{
                            height: '32px',
                            pl: 2,
                            pr: 0.5,
                            borderRadius: 5,
                            display: 'flex',
                            alignItems: 'center',
                            width: '290px',
                            border: `1px solid ${colors.border} `,
                          }}
                        >
                          <Input
                            value={searchKey}
                            onChange={(e) => {
                              setSearchKeyString(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') searchApplication();
                            }}
                            disableUnderline
                            fullWidth
                            sx={{ fontSize: '12px' }}
                            placeholder="Search by application or beneficiary name"
                          />
                          {searchKey && searchLoading !== 'loading' && (
                            <IconButton
                              title="clear"
                              sx={{ p: 0.5 }}
                              onClick={() => setSearchKeyString('')}
                            >
                              <ClearIcon fontSize="small" sx={{ color: colors.grey700 }} />
                            </IconButton>
                          )}
                          {searchLoading !== 'loading' && (
                            <IconButton
                              title="search"
                              sx={{ p: 0.5 }}
                              onClick={() => searchApplication()}
                            >
                              <SearchIcon fontSize="small" sx={{ color: colors.grey700 }} />
                            </IconButton>
                          )}
                          {searchLoading === 'loading' && (
                            <CircularProgress size="18px" sx={{ mr: 1, ml: 0.5 }} />
                          )}
                        </Box>
                      )}
                    {/* <IconButton>
                      <NotificationsIcon sx={{ color: colors.grey700 }} />
                    </IconButton> */}
                  </Box>
                </Box>
              </Toolbar>
            </Box>
            {children}
          </Box>
        </Box>
      ) : (
        <Box>{children}</Box>
      )}
    </div>
  );
};

export default Layout;

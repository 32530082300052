import { FC } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';

const PrivateRoute: FC<any> = ({}) => {
  const auth = !!localStorage.getItem('accessToken');
  const location = useLocation();
  if (!auth) {
    // not logged in, redirect to login page
    return <Navigate to={{ pathname: '/login' }} state={{ from: location.pathname }} />;
  }
  // authorized, return component
  return <Outlet />;
};

export default PrivateRoute;
